/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';

import Header from "./header"
import "./layout.css"
import 'bootswatch/dist/sandstone/bootstrap.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Container fluid>
          <Row className="last-row">
            <Container>
              <Row>
                <Col xs={12}>
                  Website &amp; Email by the folks at <a href="https://koaandco.com/">koa &amp; co.</a>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
