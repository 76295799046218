import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = ({ siteTitle }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Navbar color="dark" dark expand="md" fixed="top">
      <NavbarBrand href="/" className="brand-name">{siteTitle}</NavbarBrand>
      <NavbarToggler onClick={() => setToggle(!toggle)} />
      <Collapse isOpen={toggle} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="mailto:events@audire.sg">
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              events@audire.sg
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="tel:+6598630479">
              <FontAwesomeIcon icon={faPhone} className="mr-1" />
              +65 9863 0479
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
